import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { usePageName } from "react-page-name";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./downloads.scss";

const Downloads = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.downloads") + " | " + t("site.pageName"));

    const location = useLocation();
    const [downloads, setDownloads] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const parts = location.pathname.split("/").filter(Boolean);

    const breadcrumbs = parts.map((part, index) => {
        const path = "/" + parts.slice(0, index + 1).join("/");
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ? (
                    <span>{part}</span>
                ) : (
                    <Link to={path}>{part}</Link>
                )}
            </li>
        );
    });

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(
                    `https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/downloads`
                );
                setDownloads(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllRef();
    }, [lang]);

    // Extract unique categories from downloads
    const uniqueCategories = Array.from(
        new Set(downloads?.map((download) => download.acf.kategorie))
    );

    return (
        <div className="downloads">
            <div className="header">
                <h1>{t ("site.downloads")}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                </ul>
                <div className="image" />
            </div>
            <div className="container">
                <div className="grid">
                    {uniqueCategories.map((category) => (
                        <div className="column" key={category}>
                            <h1>{category}</h1>
                            <div className="list">
                                {isLoading ? (
                                    <div className="loading" />
                                ) : (
                                    downloads
                                        .filter((download) => download.acf.kategorie === category)
                                        .map((filteredDownload) => (
                                            <Link
                                                to={filteredDownload.acf.file}
                                                key={filteredDownload.id}
                                                target="_blank"
                                                className="item"
                                            >
                                                <span>{filteredDownload.acf.datatype}</span>
                                                <h2>{filteredDownload.title.rendered}</h2>
                                            </Link>
                                        ))
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Downloads;
