import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import "./viewLeistungen.scss"
import { usePageName } from 'react-page-name';

function ViewLeistungen() {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const referenz = location.pathname.split("/")[3];
    const parts = location.pathname.split("/").filter(Boolean);

    const [data, setReferenz] = useState([]);
    const [referenzen, setReferenzen] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const breadcrumbs = parts.map((part, index) => {
        const path = '/' + parts.slice(0, index + 1).join('/');
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ?
                    <span>{part}</span> :
                    <Link to={path}>{part}</Link>
                }
            </li>
        );
    });

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/dienstleistungen?slug=${referenz}`)
                setReferenz(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [referenz, lang])

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/referenzen?per_page=100`);
                const allReferenzen = res.data;

                // Filter referenzen based on the condition
                const filteredReferenzen = allReferenzen.filter((referenz) =>
                    data.title.rendered === referenz.acf.art
                );

                setReferenzen(filteredReferenzen);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllRef();
    }, [data, lang]);

    usePageName(isLoading ? `Laden | Huber & Feichter GmbH` : `${data.title.rendered} | Huber & Feichter GmbH`);

    return (
        <div className='viewLeistungen'>
            <div className='header'>
                <h1>{isLoading ? "" : data.title.rendered}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs.slice(0, -1)} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                    <li className={`level-${breadcrumbs.length + 1}`}>
                        <span style={{ textTransform: "inherit" }}>{!isLoading && data.title.rendered}</span>
                    </li>
                </ul>
                {isLoading
                    ? <div className='image'></div>
                    : <>
                        <div className='image' style={{ backgroundImage: "url(" + data.acf.image + ")", }} />
                    </>
                }
            </div>
            <div className="container">
                {isLoading
                    ? <div className='loading' />
                    : <div className='content'>
                        <p>{data.acf.beschreibung}</p>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </div>
                }
                <div className="gridListe">
                    <div className="heading">
                        <h1>{t("site.referenzen")}</h1>
                        <h4>{isLoading ? "" : data.title.rendered}</h4>
                    </div>
                    <div className="grid">
                        {isLoading ? <div className='loading' /> : referenzen.slice(0, 4).map(referenzen => (
                            <NavLink to={`/${i18n.language}/referenzen/${referenzen.slug}`} key={referenzen.id} className="item">
                                <img
                                    className="image"
                                    src={referenzen.acf.image}
                                    alt={referenzen.title.rendered}
                                />
                                <div className="content">
                                    <span>{referenzen.acf.art}</span>
                                    <h3>{referenzen.title.rendered}</h3>
                                    <p>{referenzen.acf.beschreibung}<TrendingFlatIcon /></p>
                                </div>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewLeistungen
