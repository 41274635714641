import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../footer/footer.scss"


const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="top">
                    <div className="left">
                        <h3>{t ("site.pageName")}</h3>
                        <p>{t ("impressum.adresse")}</p>

                        <Link to={"tel:+39 0474 479 555"}>
                            <span>T.</span>
                            <span>+39 0474 479 555</span>
                        </Link>
                        <Link to={"mailto:info@huberfeichter.it"}>
                            <span>E.</span>
                            <span>info@huberfeichter.it</span>
                        </Link>
                        <Link to={"mailto:huberfeichter@pec.bz.it"}>
                            <span>PEC.</span>
                            <span>huberfeichter@pec.bz.it</span>
                        </Link>
                        <Link>
                            <span>{t ("impressum.empfängercodex")}.</span>
                            <span>T04ZHR3</span>
                        </Link>
                    </div>
                    <div className="right">
                        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.42 364.97">
                            <path className="svg60" d="M227.59,451a99.83,99.83,0,1,1,97-76" transform="translate(-107.18 -108.19)" />
                            <line className="svg60" x1="46.73" y1="175.41" x2="196.29" y2="13.93" />
                            <path d="M348.55,214a69.94,69.94,0,1,1-69.94,69.94A69.94,69.94,0,0,1,348.55,214m0-41A111,111,0,1,0,427,205.46,110.18,110.18,0,0,0,348.55,173Z" transform="translate(-107.18 -108.19)" />
                            <path d="M250.34,473.16a24.41,24.41,0,0,1-10.94-2.43,18.78,18.78,0,0,1-7.74-6.84L242,455a11.18,11.18,0,0,0,3.75,3.27,9.46,9.46,0,0,0,4.5,1.14,6.67,6.67,0,0,0,5.34-2.18,9.31,9.31,0,0,0,1.89-6.28V395h14.11v56.84q0,10.13-5.56,15.73T250.34,473.16Z" transform="translate(-107.18 -108.19)" />
                            <path d="M298,473.16q-7.5,0-11.34-4.13t-3.83-12.32q0-8.56,4.82-12.93t14.18-4.37h8.94l.75,10.81h-9.32a6.8,6.8,0,0,0-5,1.62,6.55,6.55,0,0,0-1.65,4.87,4.62,4.62,0,0,0,1.6,3.83,7.18,7.18,0,0,0,4.58,1.28c2.87,0,5-.48,6.49-1.44a4.8,4.8,0,0,0,2.18-4.31l1,6.33a10.38,10.38,0,0,1-2.1,6,11.64,11.64,0,0,1-4.84,3.59A17.16,17.16,0,0,1,298,473.16Zm12.45-.8V435a5.79,5.79,0,0,0-1.86-4.61,7.78,7.78,0,0,0-5.27-1.62,9.33,9.33,0,0,0-4.52,1.09,8.51,8.51,0,0,0-3.25,3.11l-10.8-4.42a15.66,15.66,0,0,1,6.54-9.1,21.65,21.65,0,0,1,12.19-3.24q9.42,0,14.61,4.87t5.19,13.7v37.58Z" transform="translate(-107.18 -108.19)" />
                            <path d="M335.82,472.36V399.14a122,122,0,0,0,13.84.77v72.45Zm26.82,0V436.27a7.26,7.26,0,0,0-1.7-5.19,6.46,6.46,0,0,0-4.9-1.78,6,6,0,0,0-4.73,1.81,7.36,7.36,0,0,0-1.65,5.16l-1-9.52A18.63,18.63,0,0,1,354,419a12.11,12.11,0,0,1,8-2.74q6.92,0,10.72,4.73t3.81,13.52v37.9Z" transform="translate(-107.18 -108.19)" />
                            <path d="M389,472.36V417.11h14.37v55.25Zm28.74-41a5.32,5.32,0,0,0-2-.85,12.82,12.82,0,0,0-2.76-.26,8.91,8.91,0,0,0-7.05,2.84,11.32,11.32,0,0,0-2.53,7.8l-1.12-14.1a20.13,20.13,0,0,1,6-7.72,13.15,13.15,0,0,1,8.17-2.82,8.24,8.24,0,0,1,3.11.56,6.61,6.61,0,0,1,2.42,1.62Z" transform="translate(-107.18 -108.19)" />
                            <path d="M448.17,473.16q-10.17,0-15.75-5.49t-5.59-15.43V438.13q0-10.42,5.43-16.15t15.22-5.72q9.52,0,14.82,6.31t5.3,17.8V450H436.89v-9.74h17.67v-3.93a7.17,7.17,0,0,0-2-5.57,7,7,0,0,0-5.11-2,7.13,7.13,0,0,0-5.62,2.31,9.46,9.46,0,0,0-2.05,6.47v15.06a8.49,8.49,0,0,0,2.11,6.2,7.89,7.89,0,0,0,5.93,2.15,7.26,7.26,0,0,0,7.45-5.64L467,459.74a16.48,16.48,0,0,1-6.39,10Q455.79,473.16,448.17,473.16Z" transform="translate(-107.18 -108.19)" />
                        </svg>
                    </div>
                </div>
                <hr />
                <div className="bottom">
                    <div className="left">
                        <span>© {new Date().getFullYear()} {t ("site.pageName")}</span>
                    </div>
                    <div className="right">
                        <NavLink to={`/${i18n.language}/impressum`}>{t ("site.impressum")}</NavLink>
                        <span>|</span>
                        <NavLink to={`/${i18n.language}/datenschutz`}>{t ("site.datenschutz")}</NavLink>
                        <span>|</span>
                        <NavLink to={`/${i18n.language}/kontakt`}>{t ("site.kontakt")}</NavLink>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer