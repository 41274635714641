import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import axios from "axios";
import "./ueber-uns.scss";

const Kontakt = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.ueberUns" ) + " | " + t("site.pageName"));

    const location = useLocation();
    const [geschichte, setGeschichte] = useState(null);
    const [kontakte, setKontakt] = useState(null);
    const [visiblePosts, setVisiblePosts] = useState(2);
    const [loadMore, setLoadMore] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const parts = location.pathname.split("/").filter(Boolean);

    const breadcrumbs = parts.map((part, index) => {
        const path = "/" + parts.slice(0, index + 1).join("/");
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ? (
                    <span>{part}</span>
                ) : (
                    <Link to={path}>{part}</Link>
                )}
            </li>
        );
    });

    useEffect(() => {
        const fetchAllGeschichte = async () => {
            try {
                const res = await axios.get(
                    `https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/geschichte`
                );

                const sortedData = res.data.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });

                setGeschichte(sortedData.reverse());
            } catch (err) {
                console.log(err);
            }
        };

        const fetchAllKontakte = async () => {
            try {
                const res = await axios.get(
                    `https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/kontakte`
                );
                setKontakt(res.data);
            } catch (err) {
                console.log(err);
            }
        };

        setLoading(false);

        fetchAllGeschichte();
        fetchAllKontakte();
    }, [lang]);

    // Function to load more posts
    const loadMorePosts = () => {
        setVisiblePosts(visiblePosts === geschichte.length ? 2 : geschichte.length);
        setLoadMore(visiblePosts === geschichte.length)
    }

    return (
        <div className="ueberUns">
            <div className="header">
                <h1>{t("site.ueberUns" )}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                </ul>
                <div className="image" />
            </div>
            <div className="container">
                <div className="grid">
                    <div className="left">
                        <h2>{t ("überUns.heading")}</h2>
                        <p>{t ("überUns.textHeaderOben")}</p>
                        <p>{t ("überUns.textHeaderUnten")}</p>
                    </div>
                    <div className="right">
                        <img src="https://www.huberfeichter.it/admin/wp-content/uploads/2023/10/MTN05889-scaled.jpg" alt="Kontakt" />
                    </div>
                </div>
                <div className="geschichte">
                    <div className="timeline"></div>
                    <div className="list">
                        {isLoading ? (
                            <div className='loading' />
                        ) : (
                            geschichte && geschichte.slice(0, visiblePosts).map(geschichte => (
                                <div className="item" key={geschichte.id}>
                                    <div className={geschichte.acf.kategorie === "groß" ? "point" : "point small"} />
                                    <div className="content">
                                        <img src={geschichte.acf.image} alt={geschichte.title.rendered} />
                                        <h3>{geschichte.title.rendered}</h3>
                                        {isLoading
                                            ? <div className='loading' />
                                            : <div className='inhalt'>
                                                <div className="wp-content" dangerouslySetInnerHTML={{ __html: geschichte.content.rendered }} />
                                            </div>
                                        }
                                    </div>
                                    <div className="year">
                                        <p>{geschichte.acf.kategorie === "groß" && geschichte.acf.year}</p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <div className="mehr">
                        <button onClick={loadMorePosts}>
                            {loadMore ? t("btn.mehrAnzeigen") : t("btn.wenigerAnzeigen")}
                        </button>
                    </div>
                </div>
                <div className="gridListe">
                    <h1>{t ("überUns.unserTeam")}</h1>
                    <div className="grid">
                        {isLoading ? (
                            <div className='loading' />
                        ) : (
                            kontakte && kontakte.map(kontakt => (
                                <div key={kontakt.id} className="item">
                                    <img
                                        className="image"
                                        src={kontakt.acf.image || "https://www.huberfeichter.it/admin/wp-content/uploads/2023/12/huberfeichter-platzhalter-team.jpg"}
                                        alt={kontakt.title.rendered}
                                    />
                                    <div className="content">
                                        <h3>{kontakt.title.rendered}</h3>
                                        <span>{kontakt.acf.position}</span>
                                        {kontakt.acf.phone &&
                                            <Link to={`tel:${kontakt.acf.phone}`}>
                                                <span>T.</span>
                                                <span>{kontakt.acf.phone}</span>
                                            </Link>
                                        }
                                        {kontakt.acf.mobile &&
                                            <Link to={`tel:${kontakt.acf.mobile}`}>
                                                <span>M.</span>
                                                <span>{kontakt.acf.mobile}</span>
                                            </Link>
                                        }
                                        {kontakt.acf.email &&
                                            <Link to={`mailto:${kontakt.acf.email}`}>
                                                <span>E.</span>
                                                <span>{kontakt.acf.email}</span>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
