import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

import Home from "./pages/home/home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Jobs from "./pages/jobs/jobs";
import ViewJobs from "./pages/jobs/viewJobs/viewJobs";
import NotFound from "./pages/notfound/notfound";
import Infopanel from "./pages/infopanel/infopanel";
import ViewReferenzen from "./pages/referenzen/viewReferenzen/viewReferenzen";
import Referenzen from "./pages/referenzen/referenzen";
import Template from "./pages/template/template";
import Downloads from "./pages/downloads/downloads";
import Kontakt from "./pages/kontakt/kontakt";
import Fuhrpark from "./pages/fuhrpark/fuhrpark";
import ViewFuhrpark from "./pages/fuhrpark/viewFuhrpark/viewFuhrpark";
import UeberUns from "./pages/ueber-uns/ueber-uns";
import Leistungen from "./pages/leistungen/leistungen";
import ViewLeistungen from "./pages/leistungen/viewLeistungen/viewLeistungen";

// Import your translation files for German and Italian
import translationDE from "./translations/de.json";
import translationIT from "./translations/it.json";

const isItalian = window.location.pathname.startsWith('/it');

i18n.init({
  interpolation: { escapeValue: false },
  lng: isItalian ? 'it' : 'de',
  resources: {
    de: {
      translation: translationDE,
    },
    it: {
      translation: translationIT,
    },
  },
});

function App() {

  const Layout = () => {
    return (
      <div className="site">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/:lang",
      element: <Layout />,
      children: [
        {
          path: "/:lang",
          element: <Home />,
        },
        {
          path: "/:lang/fuhrpark",
          element: <Fuhrpark />,
        },
        {
          path: "/:lang/fuhrpark/:title",
          element: <ViewFuhrpark />,
        },
        {
          path: "/:lang/jobs",
          element: <Jobs />,
        },
        {
          path: "/:lang/jobs/:title",
          element: <ViewJobs />,
        },
        {
          path: "/:lang/ueber-uns",
          element: <UeberUns />,
        },
        {
          path: "/:lang/kontakt",
          element: <Kontakt />,
        },
        {
          path: "/:lang/downloads",
          element: <Downloads />,
        },
        {
          path: "/:lang/datenschutz",
          element: <Template />,
        },
        {
          path: "/:lang/impressum",
          element: <Template />,
        },
        {
          path: "/:lang/referenzen",
          element: <Referenzen />,
        },
        {
          path: "/:lang/referenzen/:title",
          element: <ViewReferenzen />,
        },
        {
          path: "/:lang/leistungen",
          element: <Leistungen />,
        },
        {
          path: "/:lang/leistungen/:title",
          element: <ViewLeistungen />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/infopanel/view",
      element: <Infopanel />,
    },
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  );
}

export default App;
