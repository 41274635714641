import React, { useState, useEffect } from "react";
import axios from "axios";
import "./slideshow.scss"

const Slideshow = () => {
    const [images, setImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);

    const fetchImages = async () => {
        try {
            const response = await axios.get("https://www.huberfeichter.it/admin/wp-json/wp/v2/infopanel?per_page=100");
            setImages(response.data);
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    useEffect(() => {
        fetchImages(); // Initial fetch

        const interval = setInterval(() => {
            fetchImages(); // Fetch images every 10 minutes
        }, 600000); // 10 minutes in milliseconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(prevImage => (prevImage + 1) % images.length);
        }, 20000); // Change image every 20 seconds

        return () => clearInterval(interval);
    }, [images]);

    return (
        <div className="slideshow">
            {images.map((image, index) => (
                <div key={index} className={index === currentImage ? "slide active" : "slide"}>
                    <img src={image.acf.image} alt={image.title.rendered} />
                </div>
            ))}
        </div>
    )
};

export default Slideshow;
