import React, { useState, useEffect } from "react";
import "./wetter.scss";

// Weitere Wetter Icons: https://github.com/Makin-Things/weather-icons/tree/main/static

const Wetter = () => {
    const [wetterdaten, setWetterdaten] = useState([]);

    const fetchData = () => {
        const apiKey = '%09yWgVIwrHm0eTUAcvjO59dJ0ldvqQRhkG';
        const ortKey = '216275';

        fetch(`https://dataservice.accuweather.com/forecasts/v1/daily/5day/${ortKey}?apikey=${apiKey}&language=de-de&metric=true&details=true`)
            .then(response => response.json())
            .then(data => setWetterdaten(data.DailyForecasts.slice(0, 3)))
            .catch(error => console.error('Fehler beim Laden der Wetterdaten:', error));
    };

    useEffect(() => {
        fetchData(); // Fetch data when component mounts

        const interval = setInterval(() => {
            fetchData(); // Fetch data every hour
        }, 3600000); // 3600000 milliseconds = 1 hour

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount


    return (
        <div className="wetter">
            {wetterdaten.map((tag, index) => (
                <div key={index} className="item">
                    <div className="day">
                        <h2>{new Date(tag.Date).toLocaleDateString('de-DE', { weekday: 'short' })}</h2>
                        <div className="temperatur">
                            <p className="max">{(tag.Temperature.Maximum.Value).toFixed(0)}°{tag.Temperature.Maximum.Unit}</p>
                            <p className="min">{(tag.Temperature.Minimum.Value).toFixed(0)}°{tag.Temperature.Minimum.Unit}</p>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={`https://developer.accuweather.com/sites/default/files/${tag.Day.Icon.toString().padStart(2, '0')}-s.png`} alt="Sonnig" />
                        <p className="description">{tag.Day.IconPhrase}</p>
                    </div>
                    <div className="temperatur">
                        <p className="max">{(tag.Temperature.Maximum.Value).toFixed(0)}°{tag.Temperature.Maximum.Unit}</p>
                        <p className="min">{(tag.Temperature.Minimum.Value).toFixed(0)}°{tag.Temperature.Minimum.Unit}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Wetter;
