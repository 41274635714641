import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePageName } from 'react-page-name';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moment from 'moment';
import "./viewJobs.scss"

function ViewJobs() {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const job = location.pathname.split("/")[3];

    const [formData, setFormData] = useState({
        frage_1: '',
        frage_2: '',
        frage_3: [],
    });

    const [data, setPost] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isBewerben, setBewerben] = useState(false);
    const [isEmailSend, setEmailSend] = useState(false);
    const lang = i18n.language === "it" ? "it/" : "";

    useEffect(() => {
        const fetchAllPost = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/jobs?slug=${job}`);
                setPost(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPost()
    }, [job, lang])

    const handleChange = (e, index) => {
        const updatedFormData = { ...formData };

        if (e.target.name === 'Radio1') {
            updatedFormData.frage_1 = e.target.value;
        } else if (e.target.name === 'Radio2') {
            updatedFormData.frage_2 = e.target.value;
        } else if (e.target.name === 'Text1') {
            updatedFormData.frage_3[index] = e.target.value;
        }

        setFormData(updatedFormData);
    };

    const allFieldsFilled = () => {
        return Object.values(formData).every(value => value !== '' && (Array.isArray(value) ? value.length > 0 : true));
    };

    const sendEmail = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://huberfeichter.2ix.de/sendMail.php', {
                job: data.title.rendered,
                frage_1: formData.frage_1,
                frage_2: formData.frage_2,
                name: formData.frage_3[0],
                email: formData.frage_3[1],
                tel: formData.frage_3[2],
            });

            console.log("Response:", response.data);

            setEmailSend(true);
            setBewerben(false);

            setTimeout(() => {
                setEmailSend(false);
            }, 5000);

        } catch (err) {
            console.error('Error sending email:', err);
        }
    };

    usePageName(isLoading ? `Laden | ` + t("site.pageName") : `${data.title.rendered} | ` + t("site.pageName"));

    return (
        <div className='viewJobs'>
            <div className='header'>
                <h1>{t("site.jobs")}</h1>
            </div>
            <div className="container">
                {isLoading
                    ? <div className='loading' />
                    : <div className='content'>
                        <div className='left'>
                            <h2>{data.title.rendered}</h2>
                            {isLoading ? <div className="loading" /> :
                                <div className={isBewerben ? "bewerben active" : "bewerben"}>
                                    <div className="detailsJobs" onClick={() => setBewerben(false)}>
                                        <h5>Jobdetails</h5>
                                        <span>Anzeigen</span>
                                    </div>
                                    <form method="post" onSubmit={sendEmail}>
                                        <div className="grid">
                                            {data.acf.forms.frage_1.bool === true && <div className="option">
                                                <h4>Hast du schon erfahrung in diesem Beruf?</h4>
                                                <div className="selection">
                                                    {data.acf.forms.frage_1.array.map((data, index) => (
                                                        <label htmlFor={11 + index} key={index}>
                                                            <input
                                                                type="radio"
                                                                name="Radio1"
                                                                id={11 + index}
                                                                value={data}
                                                                checked={formData.frage_1 === data}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="labelBody">
                                                                <span className="nr">{index}</span>
                                                                <span className="text">{data}</span>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div >
                                            </div>}
                                            {data.acf.forms.frage_2.bool === true && <div className="option">
                                                <h4>Wann könntest du bei uns beginnen?</h4>
                                                <div className="selection">
                                                    {data.acf.forms.frage_2.array.map((data, index) => (
                                                        <label htmlFor={21 + index} key={index}>
                                                            <input
                                                                type="radio"
                                                                name="Radio2"
                                                                id={21 + index}
                                                                value={data}
                                                                checked={formData.frage_2 === data}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="labelBody">
                                                                <span className="nr">{index}</span>
                                                                <span className="text">{data}</span>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div >
                                            </div>}
                                            {data.acf.forms.frage_3.bool === true && <div className="option">
                                                <h4>Deine Kontaktdaten</h4>
                                                <div className="selection">
                                                    {data.acf.forms.frage_3.array.map((data, index) => (
                                                        <label htmlFor={31 + index} key={index}>
                                                            <span className="labelTextInput">{data}</span>
                                                            <input
                                                                type="text"
                                                                name="Text1"
                                                                placeholder={data}
                                                                value={formData.frage_3[index] || ''}
                                                                onChange={(e) => handleChange(e, index)}
                                                            />
                                                        </label>
                                                    ))}
                                                </div >
                                            </div>}
                                        </div>
                                        <button type="submit" disabled={!allFieldsFilled()}>
                                            Bewerben
                                        </button>
                                    </form>
                                    {isEmailSend &&
                                        <div className="popUp">
                                            <DoneAllIcon />
                                            <span>Bewerbung erfolgreich<br />gesendet!</span>
                                            <div className="status" />
                                        </div>
                                    }
                                </div>
                            }
                            <div className={isBewerben ? "contentJobs active" : "contentJobs"}>
                                <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                            </div>
                        </div>
                        <div className="right">
                            <div className="contentBox">
                                <img src={data.acf.image} alt='Jobanzeige' width="100%" />
                                <div className="list">
                                    <div className="top">
                                        <span>{data.acf.type}</span>
                                        <span>{data.acf.time}</span>
                                    </div>
                                    <p style={{ marginBottom: "20px" }}>{t("jobs.textBewerben")}</p>
                                    <span className="postDatum">{moment(data.date).fromNow()}</span>
                                </div>
                                <div className="links">
                                    <Link className='oneClick' onClick={() => setBewerben(true)}>
                                        <AssignmentTurnedInIcon />
                                        <span>{t("jobs.bewerben")} in 1Click</span>
                                    </Link>
                                    <Link to={`mailto:info@huberfeichter.it?subject=Bewerbung: ${data.title.rendered}`} target="blank" className="eMail">
                                        <EmailIcon />
                                        <span>{t("jobs.bewerben")}</span>
                                    </Link>
                                    <Link to={"https://wa.me/message/O47YBHDMFIAJA1"} target="blank" className="whatsApp">
                                        <WhatsAppIcon />
                                        <span>{t("jobs.bewerben")}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewJobs
