import React from 'react'
import { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { usePageName } from "react-page-name";
import { useTranslation } from 'react-i18next';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import axios from "axios";
import "./leistungen.scss"
import moment from 'moment';
import 'moment/locale/de'
moment.locale('de')

const Leistungen = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.leistungen") + " | " + t("site.pageName"));

    const location = useLocation();

    const parts = location.pathname.split("/").filter(Boolean);

    const [displayedItems, setDisplayedItems] = useState(6);
    const [referenzen, setReferenzen] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = window.innerHeight + 500;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            setDisplayedItems(prev => prev + 6); // Increase the number of displayed items by 6
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const breadcrumbs = parts.map((part, index) => {
        const path = '/' + parts.slice(0, index + 1).join('/');
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ?
                    <span>{part}</span> :
                    <Link to={path}>{part}</Link>
                }
            </li>
        );
    });

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/dienstleistungen`)
                setReferenzen(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [lang])

    return (
        <div className='leistungen'>
            <div className='header'>
                <h1>{t ("site.leistungen")}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                    <li className={`level-${breadcrumbs.length + 1}`}>
                        <span>{usePageName()}</span>
                    </li>
                </ul>
            </div>
            <div className="container">
                <h2>{t ("leistungen.headingHeader")}</h2>
                <p>{t ("leistungen.textHeader")}</p>
                <div className="gridListe">
                    <div className="grid">
                        {isLoading ? <div className='loading' /> : referenzen.slice(0, displayedItems).map(referenzen => (
                            <NavLink to={`/${i18n.language}/leistungen/${referenzen.slug}`} key={referenzen.id} className="item">
                                <img
                                    className="image"
                                    src={referenzen.acf.image}
                                    alt={referenzen.title.rendered}
                                />
                                <div className="content">
                                    <span>{referenzen.acf.art}</span>
                                    <h3>{referenzen.title.rendered}</h3>
                                    <p>{referenzen.acf.beschreibung}<TrendingFlatIcon /></p>
                                </div>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leistungen
