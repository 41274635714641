import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import axios from "axios";
import "./kontakt.scss";

const Kontakt = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.kontakt") + " | " + t("site.pageName"));

    const location = useLocation();
    const [data, setKontakt] = useState(null);
    const [openingHours, setOpeningHours] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const parts = location.pathname.split("/").filter(Boolean);


    const breadcrumbs = parts.map((part, index) => {
        const path = "/" + parts.slice(0, index + 1).join("/");
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ? (
                    <span>{part}</span>
                ) : (
                    <Link to={path}>{part}</Link>
                )}
            </li>
        );
    });

    useEffect(() => {
        const fetchPlaceDetails = async () => {
            try {
                const response = await axios.get(
                    "https://maps.googleapis.com/maps/api/place/details/json", {
                    params: {
                        place_id: 'ChIJMUdoAhQfeEcRpjd4tey1FKA',
                        fields: 'opening_hours',
                        key: 'AIzaSyDcHruo0hR5wUslR32AyekdXtG1PmpMcrk',
                    },
                }
                );

                console.log(response)

                const openingHoursData = response.data.result.opening_hours;
                setOpeningHours(openingHoursData.weekday_text);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching place details:", error);
            }
        };

        fetchPlaceDetails();
    }, []);

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(
                    `https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/kontakte`
                );

                // Filter kontakte based on the condition
                const filteredKontakte = res.data.filter(
                    (kontakt) => kontakt.acf && kontakt.acf.kategorie === "Kontakte"
                );

                setKontakt(filteredKontakte);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };

        fetchAllRef();
    }, [lang]);

    return (
        <div className="kontakt">
            <div className="header">
                <h1>{t ("site.kontakt")}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                </ul>
                <div className="image" />
            </div>
            <div className="container">
                <div className="grid">
                    <div className="left">
                        <h2>{t ("site.pageName")}</h2>
                        <p>{t ("impressum.adresse")}</p>
                        <div className="links">
                            <Link to={"tel:+39 0474 479 555"}>
                                <PhoneIcon />
                                <span>+39 0474 479 555</span>
                            </Link>
                            <Link to={"mailto:info@huberfeichter.it"}>
                                <MailIcon />
                                <span>info@huberfeichter.it</span>
                            </Link>
                        </div>
                    </div>
                    <div className="right">
                        <img src="https://www.huberfeichter.it/admin/wp-content/uploads/2023/10/DJI_0026-Bearbeitet.jpg" alt="Kontakt" />
                    </div>
                </div>
                <div className="gridListe">
                    <div className="grid">
                        {isLoading ? <div className='loading' /> : data.map(kontakt => (
                            <div key={kontakt.id} className="item">
                                <img
                                    className="image"
                                    src={kontakt.acf.image || "https://www.huberfeichter.it/admin/wp-content/uploads/2023/12/huberfeichter-platzhalter-team.jpg"}
                                    alt={kontakt.title.rendered}
                                />
                                <div className="content">
                                    <h3>{kontakt.title.rendered}</h3>
                                    <span>{kontakt.acf.position}</span>
                                    {kontakt.acf.phone &&
                                        <Link to={`tel:${kontakt.acf.phone}`}>
                                            <span>T.</span>
                                            <span>{kontakt.acf.phone}</span>
                                        </Link>
                                    }
                                    {kontakt.acf.mobile &&
                                        <Link to={`tel:${kontakt.acf.mobile}`}>
                                            <span>M.</span>
                                            <span>{kontakt.acf.mobile}</span>
                                        </Link>
                                    }
                                    {kontakt.acf.email &&
                                        <Link to={`mailto:${kontakt.acf.email}`}>
                                            <span>E.</span>
                                            <span>{kontakt.acf.email}</span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="karte">
                <iframe
                    allowFullScreen=""
                    frameBorder="0"
                    loading="lazy"
                    src="https://www.google.com/maps/embed/v1/search?key=AIzaSyDcHruo0hR5wUslR32AyekdXtG1PmpMcrk&amp;q=Huber+%26+Feichter+GmbH&amp;zoom=16"
                    title="Karte"
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    );
};

export default Kontakt;
