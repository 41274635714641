import React, { useState, useEffect } from "react";
import "./infopanel.scss"
import Slideshow from "./slideshow/slideshow";
import News from "./news/news";
import Wetter from "./wetter/wetter";

const Infopanel = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatDate = (date) => {
        const options = { weekday: 'long', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    }

    const formatTime = (date) => {
        const options = { hour: 'numeric', minute: 'numeric', hour12: false };
        return date.toLocaleTimeString(undefined, options);
    }

    return (
        <div className="infopanel">
            <div className="statusbar">
                <svg version="1.1" id="Ebene_1" className="svgLogo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 589 310.2">
                    <g>
                        <g>
                            <path className="st0" d="M560,267.8h-12.4v35.3H560V267.8z M548.1,255.6l-2.9,10.7h20.1c2.2,0,6-0.2,6,3c0,4.5-7.7,8.9-11,11.4
			c4.7,10.6,10.6,20.8,18.2,29.5l10.4-7.2c-5.4-6.2-10-12.9-13.8-20.2c5.5-3.5,10.2-8.1,10.2-15c0-5.4-3.1-12.2-14.4-12.2
			L548.1,255.6z M538,255.6h-28.6v47.4h29.9l2.9-10.7h-20.4V284h14.5l2.9-10.7h-17.4v-7h19.1L538,255.6z M503,255.6h-33.1l-2.9,10.7
			h13.2v36.8h12.5v-36.8h13.2L503,255.6z M437.8,303.2v-21.9h13.1v21.8h12.4v-47.4h-12.4v15h-13.1v-15h-12.4l0.1,47.5 M420.4,292.4
			c-12.4,0.7-19.5-7.5-19.5-17.1c0-9,6.7-9,9.2-9h9.7l-2.9-10.7h-9.7c-14.2,0-19,9-19,18.6c0,15.3,11.2,28.9,27.2,28.9h2.2
			L420.4,292.4z M381.5,255.6h-12.4v47.4h12.4V255.6z M359.5,255.6h-28.6v47.4h29.9l2.9-10.7h-20.4V284h14.5l2.9-10.7h-17.3v-7h19.1
			L359.5,255.6z M323.3,255.6h-28.1v44.2c0,3.6,0.3,7.2,1.9,10.5l11.3-7.2c-0.6-2.2-0.9-4.5-0.8-6.7v-11.7h13.9l2.9-10.7h-16.8v-7.6
			h18.6L323.3,255.6z M267.5,278.6h-9.9v13.5h-4.8c-1.5,0-3.6,0-3.6-1.9c0-3.1,5.6-6.5,8-7.6l-2.6-2c-1.2-0.9-4-2.8-4-4.4
			c0-1.5,1.1-1.5,3.9-1.5h8.5l-1.9-7.1h-10.5c-7.4,0-9.2,3.8-9.2,7.3c0,3.1,1.9,5.2,4.2,7.1c-3.1,2.2-5.6,5.4-5.6,9.5
			c0,3.6,2,7.7,10.7,7.7h15v-13.5h1.8L267.5,278.6z M188.9,267.8h-12.4v35.3h12.4L188.9,267.8z M177.1,255.6l-2.9,10.7h20.1
			c2.2,0,6-0.2,6,3c0,4.5-7.7,8.9-11,11.4c4.7,10.6,10.6,20.8,18.2,29.5l10.4-7.2c-5.4-6.2-10-12.9-13.8-20.2
			c5.5-3.5,10.2-8.1,10.2-15c0-5.4-3.1-12.2-14.4-12.2L177.1,255.6z M166.9,255.6h-28.6v47.4h29.9l2.9-10.7h-20.4V284h14.5l2.9-10.7
			h-17.4v-7h19.1L166.9,255.6z M115.2,303.1c13,0,16.1-6.2,16.1-11.6c0-6-3.6-10.8-8.4-14.2c3.4-2.9,6.3-6,6.3-10.7
			c0-5.2-2.8-11-13.9-11H92.9L90,266.3h19.4c4.1,0,5.8,0,5.8,2.2c0,2.5-4.2,5.3-6,6.6l-3.9,3c3.6,1.7,12,6.8,12,11.4
			c0,2.9-3.2,2.9-5.4,2.9h-7.1v-24.7H92.3v35.3H115.2z M83.5,255.6H71.1v36.7h-4.6c-2.4,0-9.2,0-9.2-9c0-10,6.2-21.6,8.9-27.7H52.8
			c-4.4,8.8-8.2,18-8.2,27.9c0,11,4.9,19.5,18.8,19.5h20.1L83.5,255.6z M37.9,255.6H25.5v15H12.4v-15H0v44.2c0,3.6,0.3,7.2,1.9,10.5
			l11.3-7.2c-0.6-2.2-0.9-4.5-0.8-6.7v-15h13.1v21.8h12.4L37.9,255.6z"/>
                            <path className="st0" d="M383,126c10.8-21.6,16.7-34.1,23.6-46.2c5.3-9.3,11.8-14.7,18.7-17.8c20.1-7.9,34.1-6.4,34.1-6.4L432.9,0.2
			c0,0-34.4-3.5-59.3,18.2C354.1,32.8,337,70.6,337,70.6l-28.6,57.1h-44.3l30.6-65.5H231l-82.8,170.1h63.6l23-44h43.9l-22.9,44h76
			c0,0,8.9-19,21.1-44.7h97.2l28-61.6H383z"/>
                        </g>
                    </g>
                </svg>
                <div className="right">
                    <div className="weather"></div>
                    <div className="clock">
                        <div className="time">{formatTime(currentTime)}</div>
                        <div className="date">{formatDate(currentTime)}</div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <svg className="form" viewBox="0 0 100 100"><polygon points="-10,0 70,0 100,100 -10,100"></polygon></svg>
                <div className="slideshowGrid">
                    <Slideshow />
                </div>
                <div className="newsGrid">
                    <News />
                    <Wetter />
                </div>
            </div>
        </div>
    )
};

export default Infopanel