import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import axios from "axios";
import "./template.scss"

const Template = () => {
    const { i18n } = useTranslation();

    const location = useLocation();
    const [data, setPost] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const parts = location.pathname.split("/").filter(Boolean);
    const post = parts[parts.length - 1]

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/posts?slug=${post}`)
                setPost(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [post, lang])

    const breadcrumbs = parts.map((part, index) => {
        const path = '/' + parts.slice(0, index + 1).join('/');
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ?
                    <span>{!isLoading && data.title.rendered}</span> :
                    <Link to={path}>{part}</Link>
                }
            </li>
        );
    });

    usePageName(isLoading ? `Laden | Huber & Feichter GmbH` : `${data.title.rendered} | Huber & Feichter GmbH`);

    return (
        <div className="template">
            <div className='header'>
                <h1>{isLoading ? "" : data.title.rendered}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                </ul>
                <div className='image' />
            </div>
            <div className="container">
                {isLoading
                    ? <div className='loading' />
                    : <div className='content'>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </div>
                }
            </div>
        </div>
    )
};

export default Template