import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom';
import "./viewFuhrpark.scss"
import { usePageName } from 'react-page-name';

function ViewFuhrpark() {

    const location = useLocation();

    const kategorie = location.pathname.split("/")[3];
    const parts = location.pathname.split("/").filter(Boolean);

    const [data, setReferenz] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const breadcrumbs = parts.map((part, index) => {
        const path = '/' + parts.slice(0, index + 1).join('/');
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ?
                    <span>{part}</span> :
                    <Link to={path}>{part}</Link>
                }
            </li>
        );
    });

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/wp-json/wp/v2/fuhrpark?slug=${kategorie}`)
                setReferenz(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [kategorie])

    usePageName(isLoading ? `Laden | Huber & Feichter GmbH` : `${data.title.rendered} | Huber & Feichter GmbH`);

    return (
        <div className='viewFuhrpark'>
            <div className='header'>
                <h1>{isLoading ? "" : data.title.rendered}</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs.slice(0, -1)} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                    <li className={`level-${breadcrumbs.length + 1}`}>
                        <span style={{textTransform: "inherit"}}>{!isLoading && data.title.rendered}</span>
                    </li>
                </ul>
                {isLoading
                    ? <div className='image'></div>
                    : <>
                        <div className='image' style={{ backgroundImage: "url(" + data.acf.image + ")", }} />
                    </>
                }
            </div>
            <div className="container">
                {isLoading
                    ? <div className='loading' />
                    : <div className='content'>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewFuhrpark
