import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import moment from 'moment';
import 'moment/locale/de'
import "./social.scss";

moment.locale('de')

const Social = () => {
    const { t } = useTranslation();

    const [instagramData, setInstagramData] = useState(null);
    const [visiblePosts, setVisiblePosts] = useState(6); // State to track visible posts

    const accessToken = 'IGQWRNQW9hTkZAtWURTQW1vWERUS2pDdzc5UHdyd3M2NEhyWFAzVEFkS3RDM3g4TlBtSkZAkdldLTTBoLXRXR0VydldmTEw5NVRIWEJrX0JZAdEViZA1hURHBzelpnaEZAaUWZALMktMSFllMXFZAcTdMc3lpMDJTREsyTW8ZD'
    const fields = 'id,caption,media_type,media_url,thumbnail_url,permalink,username,timestamp,children';

    useEffect(() => {
        // Hier können Sie den erhaltenen OAuth 2.0-Token verwenden, um auf Instagram-Daten zuzugreifen.
        fetch(`https://graph.instagram.com/me/media?fields=${fields}&is_shared_to_feed=true&access_token=${accessToken}`)
            .then(response => response.json())
            .then(data => {
                const lastHundredPosts = data.data;
                setInstagramData({ data: lastHundredPosts });
            })
            .catch(error => console.error('Error:', error));
    }, []);

    // Function to load more posts
    const loadMorePosts = () => {
        setVisiblePosts(prev => prev + 6);
    }

    return (
        <div className='social'>
            <h1>{t("social.textH1")}</h1>
            <div className="grid">
                {instagramData && instagramData.data && instagramData.data.slice(0, visiblePosts).map(item => (
                    <Link className='item' key={item.id} to={item.permalink} target='_blank'>
                        {item.media_type === 'VIDEO' ? (
                            <div className="video">
                                <video alt={item.caption} poster={item.thumbnail_url} autoPlay muted loop >
                                    <source src={item.media_url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <span className="carousel"><PlayCircleIcon /></span>
                            </div>
                        ) : (
                            <div className="image">
                                <img src={item.media_url} alt={item.caption} />
                                {item.media_type === 'CAROUSEL_ALBUM' && (
                                    <span className="carousel"><PhotoLibraryIcon /></span>
                                )}
                            </div>
                        )}
                        <div className="caption">
                            <span>{item.caption.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}</span>
                        </div>
                    </Link>
                ))}
            </div>
            {instagramData && instagramData.data && visiblePosts < instagramData.data.length && (
                <button className='loadMore' onClick={loadMorePosts}>{t("btn.mehrAnzeigen")}</button>
            )}
        </div>
    )
}

export default Social;
