import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { usePageName } from "react-page-name";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/de'
import "./jobs.scss"
moment.locale('de')

const Jobs = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.jobs") + " | " + t("site.pageName"));

    const [jobs, setJobs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/jobs?per_page=100`)
                setJobs(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [lang])

    return (
        <div className='jobs'>
            <div className='header'>
                <h1>{t ("site.jobs")}</h1>
            </div>
            <div className="container">
                <h2>{t ("jobs.headingHeader")}</h2>
                <h4>Werde Teil unseres Teams</h4>
                <div className="contentJobs">
                    {isLoading ? <div className='loading' /> : jobs.map(jobs => (
                        <div className="job" key={jobs.id}>
                            <Link className='left' to={`/${i18n.language}/jobs/${jobs.slug}`}>
                                <div className="top">
                                    <span>{jobs.acf.type}</span>
                                    <span>{jobs.acf.time}</span>
                                </div>
                                <div className="middle">
                                    <h3>{jobs.title.rendered}</h3>
                                </div>
                                <div className="bottom">
                                    <span className="postDatum">{moment(jobs.date).fromNow()}</span>
                                </div>
                            </Link>
                            <div className="right">
                                <span>{t ("jobs.textBewerben")}</span>
                                <Link className='button' to={`/${i18n.language}/jobs/${jobs.slug}`}>
                                    <button>{t ("jobs.bewerben")}</button>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Jobs
