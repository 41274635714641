import React, { useState, useEffect } from "react";
import "./news.scss";

const News = () => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch("https://www.tagesschau.de/index~rss2.xml");
                const xmlText = await response.text();
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlText, "text/xml");
                const items = xmlDoc.querySelectorAll("item");

                const newsData = Array.from(items).map(item => {
                    const titleElement = item.querySelector("title");
                    const descriptionElement = item.querySelector("description");
                    const imageElement = item.querySelector("enclosure");

                    if (titleElement && descriptionElement && imageElement) {
                        const contentEncoded = item.querySelector("content\\:encoded, encoded").innerHTML;
                        const contentDoc = new DOMParser().parseFromString(contentEncoded, "text/html");
                        const contentElement = contentDoc.querySelector("p");

                        return {
                            title: titleElement.textContent,
                            description: descriptionElement.textContent,
                            content: contentElement ? contentElement.textContent : "",
                            image: imageElement.getAttribute("url")
                        };
                    }
                    return null;
                }).filter(Boolean);

                setNewsItems(newsData);
            } catch (error) {
                console.error("Error fetching and parsing news feed:", error);
            }
        };

        fetchNews();

        const newsRefreshInterval = setInterval(() => {
            fetchNews();
        }, 600000); // Refresh every 10 minutes

        const newsSwitchInterval = setInterval(() => {
            setCurrentItemIndex(prevIndex => (prevIndex + 1) % newsItems.length);
        }, 30000); // Switch news item every 30 seconds

        return () => {
            clearInterval(newsRefreshInterval);
            clearInterval(newsSwitchInterval);
        };
    }, [newsItems.length]);

    const currentItem = newsItems[currentItemIndex] || {};

    return (
        <div className="news">
            {currentItem.image && (
                <div className="news-item">
                    <img src={currentItem.image} alt={currentItem.title} />
                    <h3>{currentItem.title}</h3>
                    <p>{currentItem.description}</p>
                </div>
            )}
        </div>
    );
};

export default News;
