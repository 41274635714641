import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import Social from "../../components/social/social";
import axios from "axios";
import "./home.scss"

const Home = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.pageName"));

    const [dienstleistungen, setDienstleistungen] = useState([]);
    const [referenzen, setReferenzen] = useState([]);
    const [header, setHeader] = useState([]);
    const [openJobsCount, setOpenJobsCount] = useState(0);
    const [openVideoPlayer, setOpenVideoPlayer] = useState(false)
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setOpenVideoPlayer(current => !current);
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für offene Jobs
                const jobsRes = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/jobs`);
                setOpenJobsCount(jobsRes.data.length);

                // Fetch für Dienstleistungen
                const dienstleistungenRes = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/dienstleistungen`);
                setDienstleistungen(dienstleistungenRes.data);

                // Fetch für Referenzen
                const referenzenRes = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/referenzen`);
                setReferenzen(referenzenRes.data.slice(0, 3));

                // Fetch für Header
                const headerRes = await axios.get(`https://www.huberfeichter.it/admin/${lang}wp-json/wp/v2/header`);
                if (headerRes.data && headerRes.data.length > 0) {
                    setHeader(headerRes.data[0]);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    return (
        <div className="home">
            {openVideoPlayer &&
                <div className="modalVideoPlayer">
                    {!isLoading && <>
                        {header.acf.hintergrund && (
                            <>
                                <button type="button" className="closeButton" onClick={handleClick}>
                                    <CloseIcon />
                                </button>
                                {header.acf.hintergrund.endsWith(".mp4") ? (
                                    <video muted controls autoPlay src={header.acf.hintergrund} />
                                ) : (
                                    <img src={header.acf.hintergrund} alt="Hintergrundbild" />
                                )}
                            </>
                        )}
                    </>
                    }
                </div>
            }
            <div className="header-start">
                <div className="container">
                    {isLoading
                        ? <div className='loading' />
                        : <div className='content'>
                            <div className="wp-content" dangerouslySetInnerHTML={{ __html: header.content.rendered }} />
                        </div>
                    }
                </div>
                {!isLoading && <>
                    {header.acf.hintergrund.endsWith(".mp4") &&
                        <div className="openVideo">
                            <button type="button" onClick={handleClick}>
                                <PlayArrowIcon />
                                <span>{t("home.ganzesVideo")}</span>
                            </button>
                        </div>
                    }
                </>
                }
                <div className="bottomLeft">
                    <Link to={`/${i18n.language}/referenzen`}>
                        <button>{t("home.btnHeaderProjekte")}</button>
                    </Link>
                    <Link to={`/${i18n.language}/jobs`}>
                        <span className={openJobsCount > 0 ? "badge active" : "badge"}>{openJobsCount}</span>
                        <button>{t("home.btnHeaderOffeneStellen")}</button>
                    </Link>
                </div>
                {isLoading ? (
                    <div className="laden"></div>
                ) : (
                    <>
                        {
                            header.acf.hintergrund && (
                                <>
                                    {header.acf.hintergrund.endsWith(".mp4") ? (
                                        <video muted loop autoPlay src={header.acf.hintergrund} />
                                    ) : (
                                        <img src={header.acf.hintergrund} alt="Hintergrundbild" />
                                    )}
                                </>
                            )
                        }
                    </>
                )}
            </div>
            <div className="start">
                <div className="container">
                    <h1>{t("home.textStartSlogan")}</h1>
                    <p>{t("home.textStartBeschreibung")}</p>
                    <Link to={`/${i18n.language}/ueber-uns`}>
                        <button>{t("home.btnStartUnternehmen")}</button>
                    </Link>
                </div>
            </div>
            <div className="start">
                <div className="container">
                    <div className="gridListe">
                        <div className="grid">
                            {isLoading ? <div className='loading' /> : dienstleistungen.map(dienstleistungen => (
                                <NavLink to={`/${i18n.language}/leistungen/${dienstleistungen.slug}`} className="item" key={dienstleistungen.id}>
                                    <img
                                        className="image"
                                        src={dienstleistungen.acf.image}
                                        alt={dienstleistungen.title.rendered}
                                    />
                                    <div className="content">
                                        <h3>{dienstleistungen.title.rendered}</h3>
                                        <p>{dienstleistungen.acf.beschreibung}<TrendingFlatIcon /></p>
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="start contact">
                <div className="container">
                    <Social />
                </div>
            </div>
            <div className="start">
                <div className="container">
                    <div className="gridListe">
                        <h1>{t("home.textReferenzen")}</h1>
                        <div className="grid" style={{ marginBottom: 40 }}>
                            {isLoading ? <div className='loading' /> : referenzen.map(referenzen => (
                                <NavLink to={`/${i18n.language}/referenzen/${referenzen.slug}`} key={referenzen.id} className="item">
                                    <img
                                        className="image"
                                        src={referenzen.acf.image}
                                        alt={referenzen.title.rendered}
                                    />
                                    <div className="content">
                                        <span>{referenzen.acf.art}</span>
                                        <h3>{referenzen.title.rendered}</h3>
                                        <p>{referenzen.acf.beschreibung}<TrendingFlatIcon /></p>
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                        <Link to={`/${i18n.language}/referenzen`}>
                            <button className="loadMore">{t("btn.mehrAnzeigen")}</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="start jobs">
                <div className="container">
                    <div className="left">
                        <img src="https://www.huberfeichter.it/admin/wp-content/uploads/2023/09/Volvo-L-120-E-020.jpg" alt="" />
                    </div>
                    <div className="right">
                        <div className="content">
                            <h2>{t("home.textJobs")}</h2>
                            <Link to={`/${i18n.language}/jobs`}>{t("home.btnJobs")}</Link>
                        </div>
                        <img src="../assets/huberfeichter_berge.svg" alt="Berge mit Bagger" />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home