import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import "./notfound.scss"

const NotFound = () => {

    const { t } = useTranslation();

    usePageName(t("site.pageName"));

    const location = useLocation();

    const parts = location.pathname.split("/").filter(Boolean);

    const breadcrumbs = parts.map((part, index) => {
        const path = '/' + parts.slice(0, index + 1).join('/');
        return (
            <li key={index} className={`level-${index + 1}`}>
                {index === parts.length - 1 ?
                    <span>{part}</span> :
                    <Link to={path}>{part}</Link>
                }
            </li>
        );
    });

    return (
        <div className="notfound">
            <div className='header'>
                <h1>Error 404</h1>
                <ul className="breadcrumbs">
                    <li className="level-1">
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    {breadcrumbs} {/* Hier werden die generierten Breadcrumbs eingefügt */}
                </ul>
                <div className='image' />
            </div>
            <div className="container">
                <h2>{t("notFound.H2")}</h2>
                <h4>{t("notFound.H4")}</h4>
            </div>
        </div>
    )
};

export default NotFound